import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updateItemFromSelect,
  deleteFromCart,
  removeFromCartItem,
} from "../features/cart";


export default function Cart() {
  const cart = useSelector((state) => state.cart);
  const products = useSelector((state) => state.products);
  const productsItemsQuantites = products.items ? products.items.map(
    (item) => item.quantite
  ) : [];
  // console.log("productsItemsQuantites");
  // console.log(productsItemsQuantites);
  // const cartItemsQuantites = cart.cartItems.map((item) => item.quantite);
  const uniqueQuantities = [...new Set(productsItemsQuantites)];
  const min = Math.min(...uniqueQuantities);
  const max = Math.max(...uniqueQuantities);
  const options = [];
  for (let i = min; i <= max; i++) {
    options.push(<option value={i}>{i}</option>);
  }
  const dispatch = useDispatch();

  const sendCartToAPI = async () => {
    try {
      console.log("Sending cart to API...");
      console.log(cart.cartItems);
      // const response = await axios.post("localhost:3000/api/cart", {
      //   cartItems: cart.cartItems, // Send the cart items to the API
      // });
      // console.log("Cart sent to API successfully:", response.data);
      // Handle the response from the API (e.g., clear the cart, show a success message)
    } catch (error) {
      console.error("Error sending cart to API:", error);
      // Handle the error (e.g., show an error message)
    }
  };

  return (
    <div>
      <h3 className="title">Votre panier</h3>
      <div className="table-container" id="cart-items">
        <table className="table is-narrow is-fullwidth">
          <tbody>
            {cart.cartItems.length > 0 ? (
              cart.cartItems.map((item) => (
                <tr key={item.id}>
                  <td>
                    <span>{item.description}</span>
                  </td>
                  <td>
                    <div className="select is-small">
                      <select
                        name="quantite"
                        onChange={(e) =>
                          dispatch(
                            updateItemFromSelect({
                              value: e.target.value,
                              id: item.id,
                            })
                          )
                        }
                        className="w-20 p-2 rounded mr-4"
                        value={item.quantite}
                      >
                        {options}
                      </select>
                    </div>
                  </td>
                  <td>
                    <button
                      className="button is-danger is-small"
                      onClick={() => dispatch(removeFromCartItem(item))}
                    >
                      Supprimer
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <p>Votre panier est vide.</p>
            )}
          </tbody>
        </table>
      </div>

      <div className="block" id="cart-total-price">
        Total de pièces :{" "}
        {cart.cartItems.reduce((acc, curr) => acc + curr.quantite, 0)}
      </div>
      <div className="block" id="cart-buttons">
        <div className="buttons">
          <button
            className="button is-success"
            id="cart-checkout"
            // onClick={() => setShowModal(false)}
            onClick={sendCartToAPI}
          >
            Valider votre commande
          </button>
          <button
            className="button is-light"
            onClick={() => setShowModal(false)}
          >
            Continuer vos achats
          </button>
        </div>
      </div>
    </div>
  );
}
