
import React, { useEffect, useState } from "react";
import ShoppingCart from "../assets/shopping-cart.svg";
import { useSelector } from "react-redux";
// import { createPortal } from "react-dom";
import Cart from "./Cart";

export default function FloatingCartButton() {
  const [showModal, setShowModal] = useState(false);

  const cart = useSelector((state) => state.cart);

  useEffect(() => {
    // Functions to open and close a modal
    function openModal($el) {
      console.log($el);
      console.log("ici");
      $el.classList.add("is-active");
    }

    function closeModal($el) {
      $el.classList.remove("is-active");
    }

    function closeAllModals() {
      (document.querySelectorAll(".modal") || []).forEach(($modal) => {
        closeModal($modal);
      });
    }

    // Add a click event on buttons to open a specific modal
    (document.querySelectorAll(".js-modal-trigger") || []).forEach(($trigger) => {

      const modal = $trigger.dataset.target;
      console.log(modal);
      console.log($trigger.dataset.target);
      const $target = document.getElementById(modal);

      $trigger.addEventListener("click", () => {
        openModal($target);
      });
    });

    // Add a click event on various child elements to close the parent modal
    (
      document.querySelectorAll(
        ".modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button"
      ) || []
    ).forEach(($close) => {
      const $target = $close.closest(".modal");

      $close.addEventListener("click", () => {
        closeModal($target);
      });
    });

    // Add a keyboard event to close all modals
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        closeAllModals();
      }
    });

    return () => {
      // Cleanup function
      document.removeEventListener("DOMContentLoaded", openModal);
      document.removeEventListener("DOMContentLoaded", closeModal);
      document.removeEventListener("DOMContentLoaded", closeAllModals);
    };
  }, []);

  return (
    <>
      <button
        className="js-modal-trigger button is-large is-primary is-outlined-hover"
        data-target="modal-js-example"
        onClick={() => setShowModal(true)}
      >
        Panier : {cart.cartItems.length}
      </button>
      <div id="modal-js-example" className="modal">
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="box">
            <Cart />
          </div>
        </div>

        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setShowModal(false)}
        ></button>
      </div>
    </>
  );
}