import React from "react";
import { Routes, Route } from "react-router-dom";
import ShopLayout from "./../layouts/ShopLayout.tsx";
import Basket from "./../components/Basket";
import BasketDemo from "./../components/Basket/BasketDemo.js";

class Shop extends React.Component {
  render() {
    return (
      <ShopLayout>
        <Routes>
          <Route path="/" element={<Basket />} exact />
          {/* <Route path="/:id" element={<Product />} exact /> */}
          <Route path="/*" element={<Basket />} exact />
          <Route path="/demo" element={<BasketDemo />} exact />
        </Routes>
      </ShopLayout>
    );
  }
}
export default Shop;
