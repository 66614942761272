import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProductsList } from "../../features/products";
import {
  addOneToCart,
  removeFromCartItem,
  deleteFromCart,
} from "../../features/cart";
import "./products.scss";

export default function ProductsList() {
  const [showTable, setShowTable] = useState(true);
  const [showGrid, setShowGrid] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedDesignations, setSelectedDesignations] = useState([]); // State for selected designation
  const [selectedFournisseurs, setSelectedFournisseurs] = useState([]); // State for selected fournisseurs (array)
  const [selectedCategories, setSelectedCategories] = useState([]); // State for selected categories (array)

  function handleShowTable() {
    setShowTable(true);
    setShowGrid(false);
  }

  function handleShowGrid() {
    setShowTable(false);
    setShowGrid(true);
  }

  const products = useSelector((state) => state.products);

  const dispatch = useDispatch();

  if (!products.items) {
    dispatch(getProductsList());
  }

  const [showedProducts, setShowedProducts] = useState(products);

  const filteredProducts = (products.items || []).filter((item) => {
    const searchTermLower = searchTerm.toLowerCase();
    return (
      item.description.toLowerCase().includes(searchTermLower) ||
      item.designation_repere.toLowerCase().includes(searchTermLower) ||
      item.fabricant.toLowerCase().includes(searchTermLower) ||
      item.reference_fabricant.toLowerCase().includes(searchTermLower) ||
      item.fournisseur.toLowerCase().includes(searchTermLower) ||
      item.reference_fournisseur.toLowerCase().includes(searchTermLower)
    );
  });

  // Filter by category
  const categoryFilteredProducts =
    selectedCategories.length > 0
      ? filteredProducts.filter((item) =>
          selectedCategories.includes(item.category)
        )
      : filteredProducts;

  // Filter by fournisseurs (multiple selection)
  const designationFilteredProducts =
    selectedDesignations.length > 0
      ? categoryFilteredProducts.filter((item) =>
          selectedDesignations.includes(item.designation_repere)
        )
      : categoryFilteredProducts;

  // Filter by fournisseurs (multiple selection)
  const fournisseurFilteredProducts =
    selectedFournisseurs.length > 0
      ? designationFilteredProducts.filter((item) =>
          selectedFournisseurs.includes(item.fournisseur)
        )
      : designationFilteredProducts;

  const handleCheckboxChange = (id) => {
    const newSelectedProducts = [...selectedProducts];
    const index = newSelectedProducts.indexOf(id);
    if (index === -1) {
      newSelectedProducts.push(id);
    } else {
      newSelectedProducts.splice(index, 1);
    }
    setSelectedProducts(newSelectedProducts);
  };

  const handleDeleteSelected = () => {
    selectedProducts.map((item) => dispatch(deleteFromCart(item)));
  };

  const handleAddSelectedToCart = () => {
    selectedProducts.map((item) => dispatch(addOneToCart(item)));
  };

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    const newSelectedCategories = [...selectedCategories];
    const index = newSelectedCategories.indexOf(category);
    if (index === -1) {
      newSelectedCategories.push(category);
    } else {
      newSelectedCategories.splice(index, 1);
    }

    setSelectedCategories(newSelectedCategories);
  };

  const handleDesignationChange = (event) => {
    setSelectedDesignations(event.target.value);
  };

  const handleFournisseurChange = (event) => {
    const fournisseur = event.target.value;
    const newSelectedFournisseurs = [...selectedFournisseurs];

    // Toggle the fournisseur in the array
    const index = newSelectedFournisseurs.indexOf(fournisseur);
    if (index === -1) {
      newSelectedFournisseurs.push(fournisseur);
    } else {
      newSelectedFournisseurs.splice(index, 1);
    }

    setSelectedFournisseurs(newSelectedFournisseurs);
  };

  const handleDeleteFournisseur = (fournisseurToRemove) => {
    const newSelectedFournisseurs = selectedFournisseurs.filter(
      (fournisseur) => fournisseur !== fournisseurToRemove
    );
    setSelectedFournisseurs(newSelectedFournisseurs);
  };

  const handleDeleteDesignation = (designationToRemove) => {
    const newSelectedDesignations = selectedDesignations.filter(
      (designation) => designation !== designationToRemove
    );
    setSelectedDesignations(newSelectedDesignations);
  };

  // Get unique designations from products
  const uniqueDesignations = [
    ...new Set((products.items || []).map((item) => item.designation_repere)),
  ];

  // Get unique fournisseurs from products
  const uniqueFournisseurs = [
    ...new Set((products.items || []).map((item) => item.fournisseur)),
  ];

  // Filter uniqueFournisseurs to remove selectedFournisseurs
  const availableFournisseurs = uniqueFournisseurs.filter(
    (fournisseur) => !selectedFournisseurs.includes(fournisseur)
  );

  // Filter uniqueDesignations to remove selectedDesignations
  const availableDesignations = uniqueDesignations.filter(
    (designation) => !selectedDesignations.includes(designation)
  );

  const handleFournisseurClick = (fournisseur) => {
    if (selectedFournisseurs.includes(fournisseur)) {
      setSelectedFournisseurs(
        selectedFournisseurs.filter((f) => f !== fournisseur)
      );
    } else {
      setSelectedFournisseurs([...selectedFournisseurs, fournisseur]);
    }
  };

  const handleDesignationClick = (designation) => {
    if (selectedDesignations.includes(designation)) {
      setSelectedDesignations(
        selectedDesignations.filter((d) => d !== designation)
      );
    } else {
      setSelectedDesignations([...selectedDesignations, designation]);
    }
  };

  // Get unique categories from products
  const uniqueCategories = [
    ...new Set((products.items || []).map((item) => item.category)),
  ];

  return (
    <section className="section">
      <h1 className="title">Product Name</h1>
      <h2 className="subtitle">Client Name</h2>
      <p className="subtitle is-5">Yearf Of Creation</p>
      <p className="subtitle is-6">Business Reference</p>
      <hr />
      <button
        className="button is-danger is-small"
        onClick={handleDeleteSelected}
      >
        Supprimer du panier
      </button>
      &nbsp;
      <button
        className="button is-success is-small"
        onClick={handleAddSelectedToCart}
      >
        Ajouter au panier
      </button>
      <hr />
      <h2 className="subtitle is-size-6">
        <em className="is-size-4">Layout</em>
      </h2>
      <div className="tabs is-toggle is-fullwidth">
        <ul>
          <li
            className={showTable ? "is-active" : ""}
            onClick={() => handleShowTable()}
          >
            <a>
              <span>Table</span>
            </a>
          </li>
          <li
            className={showGrid ? "is-active" : ""}
            onClick={() => handleShowGrid()}
          >
            <a>
              <span>Gallery</span>
            </a>
          </li>
        </ul>
      </div>
      {/* Founrisseur Filter */}
      <div className="tags">
        {availableFournisseurs.map((fournisseur) => (
          <span
            key={fournisseur}
            className="tag is-clickable "
            onClick={() => handleFournisseurClick(fournisseur)}
          >
            {fournisseur}
          </span>
        ))}
      </div>
      <div className="field is-grouped is-grouped-multiline">
        {selectedFournisseurs.map((fournisseur) => (
          <div className="control" key={fournisseur}>
            <div className="tags has-addons">
              <a
                className="tag is-primary is-clickable"
                onClick={() => handleDeleteFournisseur(fournisseur)}
              >
                {fournisseur}
              </a>
              <a
                className="tag is-delete is-clickable"
                onClick={() => handleDeleteFournisseur(fournisseur)}
              ></a>
            </div>
          </div>
        ))}
      </div>
      {/* Designation Filter */}
      <div className="tags">
        {availableDesignations.map((designation) => (
          <span
            key={designation}
            className="tag is-clickable "
            onClick={() => handleDesignationClick(designation)}
          >
            {designation}
          </span>
        ))}
      </div>
      <div className="field is-grouped is-grouped-multiline">
        {selectedDesignations.map((designationn) => (
          <div className="control" key={designationn}>
            <div className="tags has-addons">
              <a
                className="tag is-primary is-clickable"
                onClick={() => handleDeleteDesignation(designationn)}
              >
                {designationn}
              </a>
              <a
                className="tag is-delete is-clickable"
                onClick={() => handleDeleteDesignation(designationn)}
              ></a>
            </div>
          </div>
        ))}
      </div>
      <div className="field">
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder="Search products..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {showTable && (
        <div className="table-container">
          <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange("all")}
                  />
                </th>
                <th>Designation / Repere</th>
                <th>Description</th>
                <th>Fabricant</th>
                <th>Réf.Fabricant</th>
                <th>Fournisseur</th>
                <th>Réf.Fournisseur</th>
                <th>Quantité installée</th>
                <th>Panier</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange("all")}
                  />
                </th>
                <th>Designation / Repere</th>
                <th>Description</th>
                <th>Fabricant</th>
                <th>Réf.Fabricant</th>
                <th>Fournisseur</th>
                <th>Réf.Fournisseur</th>
                <th>Quantité installée</th>
                <th>Panier</th>
              </tr>
            </tfoot>
            <tbody>
              {fournisseurFilteredProducts &&
                fournisseurFilteredProducts.map((item) => (
                  <tr
                    className={
                      item.designation_repere == "NULL" ? "" : "is-warning"
                    }
                    key={item.id}
                  >
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedProducts.includes(item.id)}
                        onChange={() => handleCheckboxChange(item.id)}
                      />
                    </td>
                    <td className="">
                      {item.designation_repere == "NULL"
                        ? "Aucun"
                        : item.designation_repere}
                    </td>

                    <td>{item.description}</td>
                    <td>{item.fabricant}</td>
                    <td>{item.reference_fabricant}</td>
                    <td>{item.fournisseur}</td>
                    <td>{item.reference_fournisseur}</td>
                    <td>{item.quantite}</td>
                    <td>
                      <div className="buttons">
                        {item.picked && (
                          <button
                            className="button is-danger is-outlined is-small is-fullwidth"
                            onClick={() => dispatch(removeFromCartItem(item))}
                          >
                            <span>Supprimer du panier</span>
                          </button>
                        )}
                        {item.picked == false && (
                          <button
                            className="button is-success is-outlined is-small is-fullwidth"
                            onClick={() => dispatch(addOneToCart(item.id))}
                          >
                            <span>Ajouter au Panier</span>
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      {showGrid && (
        <div className="columns is-multiline">
          {fournisseurFilteredProducts &&
            fournisseurFilteredProducts.map((item) => (
              <div key={item.id} className="column is-one-quarter">
                <div className="card">
                  <div className="card-content">
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-6">{item.description}</p>
                      </div>
                    </div>

                    <div className="content">
                      <p className="subtitle is-7">
                        <strong>Quantité</strong> : {item.quantite}
                        <br />
                        <strong>Désignation repere</strong> :{" "}
                        {item.designation_repere == "NULL"
                          ? "Aucun"
                          : item.designation_repere}
                      </p>

                      <div className="buttons">
                        {item.picked && (
                          <button
                            className="button is-danger is-outlined is-small is-fullwidth"
                            onClick={() => dispatch(removeFromCartItem(item))}
                          >
                            <span>Supprimer du panier</span>
                          </button>
                        )}
                        {item.picked == false && (
                          <button
                            className="button is-success is-outlined is-small is-fullwidth"
                            onClick={() => dispatch(addOneToCart(item.id))}
                          >
                            <span>Ajouter au Panier</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
      <div className="content">
        {/* Change layout buttons */}
        <div className="tabs is-toggle">
          <ul>
            <li className={showTable ? "is-active" : ""}>
              <a onClick={() => handleShowTable()}>
                <span>Table</span>
              </a>
            </li>
            <li className={showGrid ? "is-active" : ""}>
              <a onClick={() => handleShowGrid()}>
                <span>Gallery</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
