import React from "react";
import ProductsList from "../ProductsList/ProductsList";


export default function Basket() {

  return (
    <>
      <div className="container is-fullhd">
        <ProductsList />
      </div>
    </>
  );
}
